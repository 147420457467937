<template>
	<b-container fluid id="remediation" v-if="$checkPermission('view-remediation')">
		<cg-loader :display="loading"></cg-loader>
		<cg-template-modal :template="templatePreview" :companyData="companyData" :isCampaign="true" searchText="" ref="cgTemplateModal"></cg-template-modal>
		<cg-localized-alert :alertVariant="alertVariant" ref="cgRemAlert" :alertMessage="alertMessage" :alertParams="alertParams"></cg-localized-alert>

		<!-- Remediation confirmation modal -->
		<b-modal id="remediation-confirm"  content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
			bodyClass="text-center"  footerClass='p-2' :centered='true' :title="$t('Account.ConfirmDeleteTitle')" @ok="triggerRemediation()">
			
			<p class="my-2 text-left">{{ $t('Remediation.ConfirmTitle') }}</p>
			<p class="my-2 text-left" v-html="$t('Remediation.Confirm')"></p>
		</b-modal>

		<!-- Remediation Modal preview -->
		<b-modal id="remediation-modal" size="xl" ref="remModal" :title="remediationTitle" body-class="modal-body-full-width" :cancel-title="$t('General.Close')" @show="resetModal()"
			:okDisabled="remTabIndex==3 && (!activeTargets || !(selectedTemplates.count || remLightTemplates))" :ok-title="okLabel" @ok="nextSlide">
			<cg-localized-alert :alertVariant="alertVariant" ref="cgRemModalAlert" :alertMessage="alertMessage" :alertCount="alertCount" :alertParams="alertParams"></cg-localized-alert>

			<b-tabs card v-model="remTabIndex">
				<b-tab :title="$t('Navbar.Template')" active>
					
					<b-form-group label-cols-lg="12" :label="$t('Remediation.TemplateManagement')" label-size="lg" label-class="label-profile pt-0" class="mb-2 mx-2">
						<span>{{ $t(remediationLight? 'Remediation.TemplateSelect' : 'Remediation.TemplateDeselect')}}<br></span>
					
						<!-- Template Counter -->
						<b-row align-h="end" class="mb-4" v-if="!remediationLight && !remediationIRL">
							<b-col>
								<span class="selected-template counter font-weight-bold">
									{{ $t('Campaigns.SelectedTemplates', { param: selectedTemplates.count, param1: filteredTemplates.length }) }}
								</span>
								<span class="selected-template too-few ml-4" v-if="tooFewCountryTemplates">
									<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'exclamation-triangle']"/>
									{{ $t('Campaigns.TooFewTemplatesSelected', { country: selectedTemplates.error }) }}
								</span>
							</b-col>
						</b-row>

						<!-- Template Counter for Remediation Light -->
						<b-row align-h="end" class="mb-4" v-else>
							<b-col>
								<span class="selected-template counter font-weight-bold">
									{{ $t('Campaigns.RLSelectedTemplates', { param: remediationIRL ? selectedTemplates.count : remLightTemplates }) }}
								</span>
							</b-col>
						</b-row>
						
						<!-- Email setup and Country filter -->
						<b-row class="mt-2" align-h="between" v-if="!remediationLight">
							<b-col sm="12" md="3">
								<b-input-group size="sm" v-if="!remediationsCustomizable">
									<b-form-select id="templateCountryFilter" size="sm" v-model="templateCountryFilter" :options="templateCountryOptions"></b-form-select>
								</b-input-group>
							</b-col>
							<b-col sm="12" md="6" v-if="!remediationsCustomizable">
								<b-form-group class="form-align-self-right" :label="$t('Remediation.SendEmailBy')" label-cols="auto" label-align-sm="right"
									label-size="sm" label-for="remSendEmailBy">
									<b-input-group size="sm">
										<b-form-select id="remSendEmailBy" size="sm" v-model="sendEmailBy" :options="sendEmailByOptions"></b-form-select>
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>

						<!-- Template switch between Cards and List, and Card display number button -->
						<b-row align-h="end" align-v="stretch" class="mt-2" v-if="remediationsCustomizable">
							<b-col>
								<b-form-group :label="$t('General.Show')" label-cols-sm="3" label-align-sm="right" label-size="sm" v-if="remediationsCustomizable"
									label-for="templateSelectedFilter">
									<b-input-group size="sm">
										<b-form-select class="mr-2" id="templateSelectedFilter" size="sm" v-model="templateSelectedFilter" :options="templateSelectedOptions">
										</b-form-select>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col class="col-auto">
								<b-button id="switchLayout" variant="primary" size="sm" class="d-block float-right" @click="isCardLayout = !isCardLayout">
									<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'eye']" />
										{{ isCardLayout? $t("Template.SwitchToList") : $t("Template.SwitchToCards") }}
								</b-button>
							</b-col>
						</b-row>

						<!-- Template Card Group -->
						<b-row class="mx-0 template-layout">
							<div class="mt-3 w-100 px-2" v-show="isCardLayout">
								<b-card-group v-if="filteredTemplates && filteredTemplates.length > 0">
									<cg-template-card
										v-for="(template, index) in filteredTemplates" 
										cardPerRow="4"
										:template="template"
										:companyData="companyData"
										:index="index"
										tabIndex="0"
										searchText=""
										:campaignId="currentRemediation.campaign_id" 
										:key="template.template_id"
										@preview="showPreviewModal"
										:disableChangeStatus="remediationLight"
										class="template-card"
									></cg-template-card>
								</b-card-group>
								<div v-else class="text-center line-height-200">{{ $t('General.TableFilterEmpty') }}</div>
							</div>

							<!-- Template Table layout -->
							<b-table v-show="!isCardLayout"
								id="templateListTable"
								ref="templateTable"
								:items="filteredTemplates"
								:fields="templateFields"
								:sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc"
								no-border-collapse
								sticky-header="430px"
								hover striped show-empty
								:emptyFilteredText="$t('General.TableFilterEmpty')"
								:empty-text="$t('General.TableEmpty')"
								@row-clicked="showPreviewModal"
								class="w-100 rem-template-table mb-0"
								>		

								<!-- Additional template info for Template type -->
								<template v-slot:cell(type)="data">
									<div class="text-center">
										<font-awesome-icon :icon="getTemplateTypeIcon(data.item.type)" />
									</div>
								</template>

								<template v-slot:cell(difficulty)="data">
									<div class="text-center">
									<span v-if="data.item.success_global || data.item.success_company">
										{{ Number(Math.max(Number(data.item.success_global), Number(data.item.success_company))*100).toFixed(0) + ' %' }}
									</span>
									<span v-else>{{ (Number(data.item.difficulty)*100).toFixed(0) + ' %' }}</span>
									</div>
								</template>

								<template v-slot:cell(status)="row">
									<div class="text-center btn-delete-template" :key="row.item.template_seq">
										<b-form-checkbox ref="rem_status" switch size="sm" style="z-index:1" button-variant="info" value="1" unchecked-value="0" 
											v-model="row.item.status" :name="'rem_'+row.item.name" @input="countSelectedTemplates">
											<span class="sr-only">{{ row.item.status== 1 ? $t('Template.Enabled', { name: row.item.name }) :
												$t('Template.Disabled', { name: row.item.name }) }}</span>
										</b-form-checkbox>
									</div>
								</template>
							</b-table>
						</b-row>
					</b-form-group>
				</b-tab>

				<b-tab :title="$t('Remediation.Planning')" v-if="remediationsCustomizable">
					<b-form-group label-cols-lg="12" :label="$t('Remediation.DatesManagement')" label-size="lg" label-class="label-profile pt-0" class="mb-2 mx-2">
						<span>{{ $t('Remediation.PlanningInfo') }}</span>
					
							<b-row class="mt-4">
								<!-- Campaign duration -->
								<b-col class="margin-bottom-vertical-md mt-2">
									<b-card-text class="text-center">{{ $t('Campaigns.CampaignDuration') }}</b-card-text>

									<div class="date-widget mt-4">
										<div class="timeline left"></div><div class="timeline center"></div><div class="timeline right"></div>
										<div class="day first"></div>
										<div class="day last"></div>

										<b-form-group class="mb-3">
											<b-row>
												<b-col>	
													<b-form-group class="form-align-self-center mb-0">
														<label for="plannedStartDate" class="col-12 col-form-label col-form-label-sm text-left pl-0">{{ $t('Campaigns.StartDateLabel') }}
															<font-awesome-icon :icon="['fas', 'info-circle']" size="lg" class="ml-2 pointer color-secondary" id="campStartDateInfo"></font-awesome-icon>
														</label>

														<b-tooltip class="mt-2 mr-2" triggers="hover" left target="campStartDateInfo">
															{{ $t('Remediation.CampStartDateInfo') }}
														</b-tooltip>

														<b-form-datepicker 
															id="plannedStartDate"
															:class="{ 'campaign-date': true, 'form-field-error': invalidDateField.includes('plannedStartDate') }"
															:date-format-options="{ 'year': 'numeric', 'month': '2-digit', 'day': '2-digit' }"
															v-model="campaignDates.startDate"
															:locale="$i18n.locale"
															@input="updateCampaignDates">
														</b-form-datepicker>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group class="form-align-self-center mb-0">
														<label for="campaignDuration" class="col-12 col-form-label col-form-label-sm text-left pl-0">{{ $t('Campaigns.CampaignDurationLabel') }}
															<font-awesome-icon :icon="['fas', 'info-circle']" size="lg" class="ml-2 pointer color-secondary" id="campDurationInfo"></font-awesome-icon>
														</label>

														<b-tooltip class="mt-2 mr-2" triggers="hover" left target="campDurationInfo">
															{{ $t('Remediation.CampDurationInfo') }}
														</b-tooltip>

														<b-form-input 
															id="campaignDuration" 
															:class="{ 'placeholder-bold': true, 'form-field-error': invalidDateField.includes('campaignDuration') }"
															v-model="campaignDates.plannedEnd"
															@change="updateCampaignDates" />
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<b-form-group class="form-align-self-center mb-0">
														<label for="plannedStartTime" class="col-12 col-form-label col-form-label-sm text-left pl-0">{{ $t('Campaigns.StartTimeLabel') }}
															<font-awesome-icon :icon="['fas', 'info-circle']" size="lg" class="ml-2 pointer color-secondary" id="campStartTimeInfo"></font-awesome-icon>
														</label>

														<b-tooltip class="mt-2 mr-2" triggers="hover" left target="campStartTimeInfo">
															{{ $t('Remediation.CampStartTimeInfo') }}
														</b-tooltip>
													
														<b-form-timepicker
															id="plannedStartTime"
															:class="{ 'campaign-time': true, 'form-field-error': invalidDateField.includes('plannedStartTime') }"
															v-model="campaignDates.startTime" 
															:locale="$i18n.locale"
															@input="updateCampaignDates">
														</b-form-timepicker>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group class="form-align-self-center mb-0">
														<label for="sendEmailsBy" class="col-12 col-form-label col-form-label-sm text-left pl-0">{{ $t('Campaigns.SendEmailsByLabel') }}
															<font-awesome-icon :icon="['fas', 'info-circle']" size="lg" class="ml-2 pointer color-secondary" id="sendEmailInfo"></font-awesome-icon>
														</label>

														<b-tooltip class="mt-2 mr-2" triggers="hover" left target="sendEmailInfo">
															<span v-html="$t('Remediation.SendEmailInfo')"></span>
														</b-tooltip>

														<b-form-input 
															id="sendEmailsBy" 
															:class="{ 'placeholder-bold': true, 'form-field-error': invalidDateField.includes('sendEmailsBy') }"
															v-model="campaignDates.sendEmailsBy"
															:readonly="remediationIRL"
															@change="updateCampaignDates" />
													</b-form-group>
												</b-col>
											</b-row>
										</b-form-group>
									</div>
								</b-col>
							</b-row>
					</b-form-group>
				</b-tab>

				<!-- Target Tab must be shown for Remediation Light even if view-target permission is missing -->
				<b-tab :title="$t(getUserTabLabel())" v-if="$checkPermission('view-target') || remediationLight" class="overflow-auto">

					<b-form-group label-cols-lg="12" :label="$t('Remediation.TargetManagement')" label-size="lg" label-class="label-profile pt-0" class="mb-2 mx-2" v-if="!remediationIRL">
						<span>{{ $t(remediationLight? 'Remediation.TargetDeselect' : 'Remediation.TargetInfo') }}<br></span>
						
						<!-- Target Filter Accordion Toggle -->
						<b-row align-h="end" class="border-bottom mx-1" v-if="remediationLight">
							<b-col class="col-auto mb-1 pr-0">
								<b-input-group size="sm">
									<span class="placeholder-bold">{{ $t('Remediation.Filter.Options') }}</span>
								</b-input-group>
							</b-col>
						</b-row>
						
						<!-- Target Filter Accordion Widget -->
						<b-row align-h="end" v-if="remediationLight">
							<div class="border-bottom pt-3 mb-2 w-100 mx-3">
								
								<!-- Add new Filter Select -->
								<b-form-group :label="$t('General.Add')" label-cols-sm="5" label-align-sm="right" label-size="sm" label-for="targetFilter" class="mt-2">
									<b-input-group size="sm">
										<b-form-select class="mr-4" id="targetFilter" size="sm" v-model="targetFilter" :options="targetOptions">
										</b-form-select>

										<b-button variant="darknavy" size="sm" class="mx-2" @click="applyTargetFilter">
											<span class="sr-only">{{ $t('Remediation.Filter.AddFilter') }}</span>
											<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'plus']" />
										</b-button>
									</b-input-group>
								</b-form-group>

								<!-- Filter list -->
								<div class="w-100" v-for="field in targetFilterMetadata" :key="field.id">
									<b-form-group :id="'input-group-' + field.id" v-if="field.display" :label="$t(field.label)" :label-for="field.id" label-cols-sm="3" 
										label-align-sm="right" label-size="sm" :class="'mt-2 label-'+field.id">

										<!-- Select Widget -->
										<b-input-group size="sm" v-if="field.type==='select'" >
											<b-form-select class="mr-4" :id="field.id" size="sm" v-model="targetFilterModel[field.id]" :options="field.options">
											</b-form-select>
											<b-button variant="darknavy" size="sm" class="mx-2" @click="removeTargetFilter(field.id)">
												<span class="sr-only">{{ $t('Remediation.Filter.RemoveFilter') }}</span>
												<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'trash-alt']" />
											</b-button>
										</b-input-group>

										<!-- Click Rate Widget -->
										<b-input-group size="sm" v-if="field.type==='click-rate'">
											<b-form-input :id="field.id+'_first'" v-cloak type="number" :min="0" :max="100" :step="1" :autofocus="true" size="sm" autocomplete="new-password" v-model="targetFilterModel[field.id].min"
											></b-form-input>
											<div class="input-group-append mr-4">
												<div class="input-group-text">%</div>
											</div>
											<b-form-input :id="field.id+'_second'" v-cloak type="number" :min="0" :max="100" :step="1" size="sm" autocomplete="new-password" v-model="targetFilterModel[field.id].max"
											></b-form-input>
											<div class="input-group-append mr-4">
												<div class="input-group-text">%</div>
											</div>

											<b-button variant="darknavy" size="sm" class="mx-2" @click="removeTargetFilter(field.id)">
												<span class="sr-only">{{ $t('Remediation.Filter.RemoveFilter') }}</span>
												<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'trash-alt']" />
											</b-button>
										</b-input-group>

										<!-- Target Risk Widget -->
										<div size="sm" v-if="field.type==='target-risk'">
											<b-container class="d-flex justify-content-between pr-0">
												<b-row class="w-100 mr-4">
													<b-col @click="toggleFilterModel('target_risk', RiskGroup.Serial)" class="text-center target-risk-cell" 
														:class="[targetFilterModel[field.id][RiskGroup.Serial]? 'active' : 'inactive']">
														{{ $t('Analytics.Table.SerialClickers') }}<br>
														<font-awesome-icon class="vertical-align-middle" :icon="['fas', targetFilterModel[field.id][RiskGroup.Serial]? 'check' : 'equals']" />
													</b-col>
													<b-col @click="toggleFilterModel('target_risk', RiskGroup.Frequent)" class="text-center target-risk-cell" 
														:class="[targetFilterModel[field.id][RiskGroup.Frequent]? 'active' : 'inactive']">
														{{ $t('Analytics.Table.FrequentClickers') }}<br>
														<font-awesome-icon class="vertical-align-middle" :icon="['fas', targetFilterModel[field.id][RiskGroup.Frequent]? 'check' : 'equals']" />
													</b-col>
													<b-col @click="toggleFilterModel('target_risk', RiskGroup.Rare)" class="text-center target-risk-cell" 
														:class="[targetFilterModel[field.id][RiskGroup.Rare]? 'active' : 'inactive']">
														{{ $t('Analytics.Table.RareClickers') }}<br>
														<font-awesome-icon class="vertical-align-middle" :icon="['fas', targetFilterModel[field.id][RiskGroup.Rare]? 'check' : 'equals']" />
													</b-col>
													<b-col @click="toggleFilterModel('target_risk', RiskGroup.Defender)" class="text-center target-risk-cell" 
														:class="[targetFilterModel[field.id][RiskGroup.Defender]? 'active' : 'inactive']">
														{{ $t('Analytics.Table.DefenderUsers') }}<br>
														<font-awesome-icon class="vertical-align-middle" :icon="['fas',targetFilterModel[field.id][RiskGroup.Defender]? 'check' : 'equals']" />
													</b-col>
												</b-row>
												<b-button class="target-risk-remove-filter d-inline mx-2" variant="darknavy" size="sm" @click="removeTargetFilter(field.id)">
													<span class="sr-only">{{ $t('Remediation.Filter.RemoveFilter') }}</span>
													<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'trash-alt']" />
												</b-button>
											</b-container>
										</div>

										<!-- Last Campaigns Widget -->
										<div size="sm" v-if="field.type==='campaign'">
											<b-container class="d-flex justify-content-between pr-0">
												<b-row class="w-100 mr-4">
													<b-col v-for="camp in field.data" :key="camp.campaign_id" @click="toggleFilterModel('campaign', camp.campaign_id)" 
														class="text-center target-risk-cell" 
														:class="targetFilterModel['campaign'][camp.campaign_id]==1?'not-clicked':targetFilterModel['campaign'][camp.campaign_id]==-1?'active':'inactive'">
														<span class="widget-title">{{ camp.name }}</span><br>
														<font-awesome-icon class="vertical-align-middle" 
															:icon="['fas', targetFilterModel['campaign'][camp.campaign_id]==1 || targetFilterModel['campaign'][camp.campaign_id]==-1 ? 'mouse':'equals']" />
													</b-col>
												</b-row>

												<b-button class="target-risk-remove-filter d-inline mx-2" variant="darknavy" size="sm" @click="removeTargetFilter(field.id)">
													<span class="sr-only">{{ $t('Remediation.Filter.RemoveFilter') }}</span>
													<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'trash-alt']" />
												</b-button>
											</b-container>

											<div class="past-behaviour-legend mt-2 font-14 font-italic">
												{{ $t('Remediation.Legend') }}
												<div class="campaign-legend clicked ml-2"><font-awesome-icon class="vertical-align-middle" :icon="['fas', 'mouse']" /></div>
												{{ $t('Remediation.UserHasClicked') }}
												<div class="campaign-legend not-clicked ml-4"><font-awesome-icon class="vertical-align-middle" :icon="['fas', 'mouse']" /></div>
												{{ $t('Remediation.UserHasNotClicked') }}
											</div>
										</div>
									</b-form-group>
								</div>
								<b-button variant="primary" :disabled="!alertChangedQuery" class="ml-2 mb-3 float-right" @click="buildQuery">
									<span class="placeholder-bold">{{ $t('Remediation.Filter.BuildQuery') }}</span>
								</b-button>
							</div>
						</b-row>
					
						<span class="font-weight-bold float-right my-2" v-if="!remediationLight">{{ $t('Remediation.TargetActive', { param: activeTargets }) }}</span>
						<!-- Selected Target Counter for Remediation Light -->

						<b-row align-h="end" :class="!alertChangedQuery? 'mb-4' : 'mb-0'">
							<b-col md="12" class="align-self-right">
								<span class="font-weight-bold active-target-counter" v-if="remediationLight" :class="activeTargets>0 && activeTargets >= minTargets? 'target-valid' : 'target-invalid'">
									{{ $t('Remediation.TargetSelected', { param: activeTargets }) }}
								</span>
								<span class="font-weight-bold" v-if="alertChangedQuery && remediationLight">
									{{ $t('Remediation.Filter.ReApplyFilters') }}
								</span>
							</b-col>
						</b-row>		
						<div class="pt-4" id="remediationTargetTableWrapper" v-if="showTargetDetails">
							<b-table
								id="remediationTargets"
								:fields="targetFields"
								:items="remediationTargets"
								:sort-by.sync="targetTableParams.sortBy"
								:sort-desc.sync="targetTableParams.sortDesc"
								:tbody-tr-class="rowClass"
								:per-page="targetTableParams.perPage"
								:current-page="targetTableParams.currentPage"
								table-class="unstriped"
								sticky-header="444px"
								no-border-collapse
								responsive
								hover
								show-empty
								:emptyFilteredText="$t('Analytics.Table.EmptyFilter')"
								:empty-text="emptyAfterFiltered"
							>

								<template v-slot:cell(click_rate)="data">
									<div class="text-center">
										<span v-if="remediationLight">{{ Number(data.item.click_rate) + ' %' }}</span>
										<span v-else>{{ (Number(data.item.click_rate)*100).toFixed(0) + ' %' }}</span>
									</div>
								</template>

								<template v-slot:cell(deleted)="row">
									<div class="text-center" :key="row.item.deleted">
										<b-form-checkbox switch size="sm" style="z-index:1" button-variant="info" value="null" unchecked-value="1" 
											@input="countActiveTargets" v-model="row.item.deleted">
											<span class="sr-only">{{ row.item.deleted == 1 ? $t('Analytics.Table.Inactive', { name: row.item.target_id }) :
												$t('Analytics.Table.Active', { name: row.item.target_id }) }}</span>
										</b-form-checkbox>
									</div>
								</template>
							</b-table>

							<b-row align-h="end">
								<b-col class="mb-2 d-block d-sm-block d-md-none">
									<label for="itemsPerPageSmall" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
									<b-form-input id="itemsPerPageSmall" class="float-right" v-model="targetTableParams.perPage" debounce="400"></b-form-input>
								</b-col>
								<b-col class="text-right pt-1 pr-0 d-none d-sm-none d-md-block">
									<label for="itemsPerPage" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
								</b-col>
								<b-col cols="12" md="auto" class="mb-2 pr-0 d-none d-sm-none d-md-block">
									<b-form-input id="itemsPerPage" class="float-right" v-model="targetTableParams.perPage" debounce="400"></b-form-input>
								</b-col>

								<b-col cols="12" md="auto">
									<b-pagination v-model="targetTableParams.currentPage" :total-rows="targetTableParams.totalRows" :per-page="targetTableParams.perPage" align="right" class="dawn"
										aria-controls="remediationTargets" :disabled="targetTableParams.perPage<1"></b-pagination>
								</b-col>
							</b-row>
						</div>
					</b-form-group>

					<b-form-group label-cols-lg="12" :label="$t('Remediation.AccountManagement')" label-size="lg" label-class="label-profile pt-0" class="mb-2" v-if="remediationIRL">
						<span>{{ $t('Remediation.ManageAccount') }}<br></span>

						<b-form @submit.prevent="addTargetToList">
							<table class="table mt-2 mb-0">
								<tbody>
									<b-form-group class="mb-0">
										<b-row>
											<b-col class="mb-2" v-for="(field, index) in fields" :key="index">
												<!-- Text widget -->
												<label v-if="textTypes.includes(field.type) && field.display" class="sr-only" :for="field.id">{{ $t(''+field.label) }}</label>
												<b-form-input v-if="textTypes.includes(field.type) && field.display" :id="field.id" :disabled="field.disabled"
													:required="field.required" :autofocus="index==0" autocomplete="new-password" :type="field.type"
													v-model="newApprover[field.id]" :placeholder="$t(field.placeholder)" @change="validate($event, field)"
													:class="{'form-field-error': field.error}">
												</b-form-input>
												<b-tooltip target="email" v-if="field.error && field.id == 'email' && !$checkPermission('list-company-all')">{{ $tc('General.InvalidDomain', validDomain.count, {'param': validDomain.domain}) }}</b-tooltip>

												<!-- Select widget -->
												<b-form-select v-if="field.type=='select'" :id="field.id" :disabled="field.disabled" :type="field.type"
													:required="field.required" v-model="newApprover[field.id]" :options="field.options"
													:placeholder="$t(field.placeholder)" :class="{'form-field-error': field.error}">
													<template #first>
														<b-form-select-option :value="null" disabled>{{ $t('Template.SelectLanguage') }}</b-form-select-option>
													</template>
												</b-form-select>
											</b-col>

											<b-col>
												<b-button type="submit" :disabled="!formValid" variant="primary">{{ newApprover.index > -1 ?  $t('General.Edit') : $t('Targets.Add') }}
												</b-button>
											</b-col>
										</b-row>
									</b-form-group>
								</tbody>
							</table>
						</b-form>
					</b-form-group>
	
					<b-table v-if="remediationIRL"
						class="table table-striped table-bordered table-import mt-2"
						:fields="accountApproversFields"
						:items="accountApproversList"
						ref="targetTable"
						hover striped show-empty bordered no-sort-reset
						:empty-text="$t('Campaigns.NoTestTarget')">

						<!-- Additional table template info for action column -->
						<template v-slot:cell(ID)="row">
							<div class="table-row-center">
								{{ row.index }}
							</div>
						</template>

						<template v-slot:cell(language)="row">
							{{ localizeLanguage(row.item.language) }}
						</template>

						<template v-slot:cell(actions)="row">
							<div class="table-row-center">
								<div class="action delete">
									<font-awesome-icon class="mx-2" :icon="['fas', 'trash-alt']"
										@click="deleteApprover(row.index)"/>
								</div>
								<div class="action edit">
									<font-awesome-icon class="mx-2" :icon="['fas', 'pencil-alt']"
										@click="editApprover(row.index)"/>
								</div>
							</div>
						</template>
					</b-table>
				</b-tab>

				<b-tab :title="$t('Remediation.Recap')" v-if="remediationsCustomizable" @click="updateRemediationSummary">
					<b-form-group label-cols-lg="12" :label="$t('Remediation.RecapTitle'+currentRemediation.type)" label-size="lg" label-class="label-profile pt-0" class="mb-2 mx-2">
						<b-card-group deck class="my-4 mx-4">
							<b-card border-variant="info" header-bg-variant="info" header-text-variant="light" align="center" text-variant="dark" 
								:header="$t('Navbar.Template')" class="text-center font-weight-bold">
								<b-card-text class="font-weight-bold">{{ ''+remLightTemplates || '0' }}</b-card-text>
							</b-card>
							<b-card border-variant="info" header-bg-variant="info" header-text-variant="light" align="center" text-variant="dark" 
								:header="$t(getUserTabLabel())" class="text-center font-weight-bold">
								<b-card-text>{{ ''+activeTargets || '0' }}</b-card-text>
							</b-card>
						</b-card-group>

						<b-card-group deck class="my-4 mx-4">
							<b-card border-variant="info" header-bg-variant="info" header-text-variant="light" align="center" text-variant="dark" 
								:header="$t('Remediation.Planning')" class="text-center font-weight-bold">
								<b-card-text class="font-weight-bold">{{ $t('Remediation.RecapStart') }} <b>{{ remediationLightDates.plannedStart | campaignDate($account.timezone) }}</b></b-card-text>
								<b-card-text class="font-weight-bold">{{ $t('Remediation.RecapSendEmail') }} <b>{{ remediationLightDates.sendEmailsBy | campaignDate($account.timezone) }}</b></b-card-text>
								<b-card-text class="font-weight-bold">{{ $t('Remediation.RecapDuration') }} <b>{{ remediationLightDates.plannedEnd | campaignDate($account.timezone) }}</b></b-card-text>
							</b-card>
						</b-card-group>
					</b-form-group>
				</b-tab>
			</b-tabs>
		</b-modal>

		<!-- Remediation Widgets -->
		<b-row>
			<b-col lg="6" xs="12" class="mb-4" v-for="(rem, index) in remediationData" :key="index">
				<remediation-widget @remediate="remediate" :data="rem" :type="rem.type"></remediation-widget>
			</b-col>
		</b-row>

		<b-card v-if="dataAvailable" no-body class="mb-4" style="min-height: 380px;">
		
			<b-card-header class="mb-3">
				<span class="remediation-title">{{ $t('Remediation.Results') }}</span>
			</b-card-header>

			<b-form-group :label="$t('General.Show')" label-cols-sm="5" label-align-sm="right" label-size="sm" 
				label-for="remFilter" class="mt-2">
				<b-input-group size="sm">
					<b-form-select class="mr-4" id="remFilter" size="sm" v-model="remFilter" :options="remOptions" @input="applyFilter">
					</b-form-select>
				</b-input-group>
			</b-form-group>

			<div v-if="filteredRemediations && filteredRemediations.length > 0">
				<div v-for="(el, k) in filteredRemediations" :key="k" :title="$t('Remediation.R' + el.type)" class="px-4">
					<div v-for="(item, key) in el.data" :key="key">
						<b-card :title="getRemName(item.name)" :sub-title="getRemActualEnd(item.actual_end)" class="mb-3" :key="reloadHistory">
							<p class="text-right my-0 py-0">{{ getRemSourceCampaign(item.name) }}</p>
							<b-row>
								<b-col offset-md="2">
									<remediated-widget :data="item.dataset" :type="el.type" :rem="item.campaign_id" :companyLan="companyData && companyData.default_language"></remediated-widget>
								</b-col>
							</b-row>
						</b-card>
					</div>
				</div>
			</div>
			<div v-else class="text-center" style="line-height: 200px;">{{ $t('Remediation.NoData') }}</div>
		</b-card>
	</b-container>
</template>

<script>
import RemediationWidget from "@/components/dashboard/cards/RemediationWidget";
import RemediatedWidget from "@/components/dashboard/cards/RemediatedWidget";
import companyService from '../services/company.service';
import templateService from "../services/template.service";
import syspropService from "../services/sysprop.service.js";
import campaignService from '../services/campaign.service';
import accountService from '../services/account.service';
import { Campaign, TemplateType, TemplateScope, RiskGroup } from "../../../server/common/constants";
import moment from 'moment-timezone';
import Vue from 'vue';
import { isEmail } from 'validator';

const defaultFilter = { 
	org_1: null,
	org_2: null,
	org_3: null,
	org_4: null,
	click_rate: {
		min: 0,
		max: 100
	},
	target_risk: {
		[RiskGroup.Serial]: null,
		[RiskGroup.Frequent]: null,
		[RiskGroup.Rare]: null,
		[RiskGroup.Defender]: null
	},
	campaign: {}
}

export default {
	name: "cg-remediation",
	components: {
		RemediationWidget,
		RemediatedWidget
	},
	data: () => {
		return {
			alertChangedQuery: false,
			emptyAfterFiltered: null,
			companyData: null,
			currentRemediation: null,
			remLightTemplates: 0,
			templatePreview: null,
			remediationData: [],
			showOptions: true,
			minTargets: 1,
			alertVariant: 'success',
			alertParams: null,
			alertMessage: "",
			alertCount: 0,
			targetFields: [],
			tabIndex: 0,
			remTabIndex: 0,
			remFilter: null,
			remOptions: [],
			activeTargets: 0,
			showTargetDetails: 0,
			loading: false,
			sendEmailBy: 86400*1000*7/2,
			sendEmailByOptions: [],
			templateCountryFilter: null,
			templateCountryOptions: [],
			invalidDateField: [],
			targetFilter: null,
			targetOptions: [],
			targetTableParams: {
				sortBy: 'target_id',
				sortDesc: true,
				totalRows: 0,
				perPage: 15,
				currentPage: 1
			},
			remediationLightDates: {
				plannedStart: null,
				sendEmailsBy: null,
				plannedEnd: null
			},
			fields: [
				{ id: "firstname",
					label: 'Targets.FirstName',
					type: "text",
					display: true,
					required: true,
					placeholder: 'Targets.FirstName'
				},
				{ id: "lastname",
					label: 'Targets.LastName',
					type: "text",
					display: true,
					required: false,
					placeholder: 'Targets.LastName'
				},
				{ id: "email",
					label: 'Targets.Email',
					type: "email",
					display: true,
					required: true,
					placeholder: 'Targets.Email'
				},
				{ id: "language",
					label: 'Targets.Language',
					type: "select",
					display: true,
					required: true,
					options: [],
					placeholder: 'Targets.Language'
				}
			],
			templateFields: [],
			sortBy: 'name',
			sortDesc: false,
			isCardLayout: true,
			templateSelectedFilter: 2,
			templateSelectedOptions: [],
			targetFilterMetadata: [],
			targetFilterModel: {},
			remHistory: [],
			reloadHistory: 0,
			remediationTypes: [ Campaign.Type.RemediationWeak, Campaign.Type.RemediationWorsen, Campaign.Type.RemediationClickers, Campaign.Type.RemediationLight, 
				Campaign.Type.RemediationTraining, Campaign.Type.RemediationQRCode, Campaign.Type.RemediationUSB ],
			textTypes: ['text', 'password', 'email', 'number', 'search', 'url', 'tel', 'date', 'time', 'range', 'color'],
			companyId: localStorage.getItem("cg-company"),
			isoCountries: null,
			newApprover: {
				firstname: null,
				lastname: null,
				email: null,
				language: null
			},
			formValid: true,
			accountApproversFields: [],
			accountApproversList: [],
			validDomain: {},
			emailDomain: null,
			dataAvailable: false
		};
	},
	watch:{
		targetFilterModel: {
			handler(val) {
				console.debug("Target Filter Model handler -", val);
				this.alertChangedQuery = true;
			},
			deep: true
		}
	},
	computed: {
		tooFewTemplates() {
			return this.currentRemediation && this.currentRemediation.templateList ? this.selectedTemplates.global < this.minTemplateAllowed : false;
		},
		tooFewCountryTemplates() {
			return this.currentRemediation && this.currentRemediation.templateList ? this.selectedTemplates.error !== "" : false;
		},
		selectedTemplates() {
			let temp = 0;
			let nullCountry = 0; //selected templates whitout a specific country (country = NULL)
			let global = 0; //all selected templates, unrelated to a specific country
			let countryTemp = []; //selected templates for a specific country 

			// Create temporary data to count selected templates for each country
			this.templateCountryOptions.forEach((t) => {
				if(t.value != null) {
					countryTemp[t.value] = { val: 0, label: t.text };
				}
			});

			// First count selected templates with any filters applied
			if (this.filteredTemplates) {
				this.filteredTemplates.forEach(template => {
					if (template.status == "1") {
						temp += 1;
					}
				});
			}

			// Then count globally selected templates for each country
			if (this.currentRemediation && this.currentRemediation.templateList) {
				this.currentRemediation.templateList.forEach(template => {
					if (template.status == "1") {
						global += 1;
						if(template.label) {
							template.label.forEach((t) => {
								if(countryTemp[t] && countryTemp[t].val >= 0) {
									countryTemp[t].val += 1;
								}
							});
						} else {
							nullCountry += 1;
						}
					}
				});
			}

			//  Manage error in the form of list of countries
			let error = [];
			Object.keys(countryTemp).forEach((t) => {
				countryTemp[t].val += nullCountry;
				if(countryTemp[t].val < this.minTemplateAllowed) {
					error.push(countryTemp[t].label);
				}
			});

			return { global: global, count: temp, error: error.join(', ') };
		},
		okLabel() {
			if((this.remediationsCustomizable && this.remTabIndex < 3) || this.showTargetDetails && this.remTabIndex == 0) {
				return this.$t('Remediation.Next');
			} else {
				return this.$t('Remediation.Approve');
			}
		},
		filteredRemediations() {
			let rems = this.remHistory;
			if(this.remFilter) {
				rems = rems.filter((r) => { return r.type == this.remFilter; });
				rems = rems.sort((a, b) => a.type - b.type);
			}
			return rems;
		},
		filteredTemplates() {
			let tmp = this.currentRemediation && this.currentRemediation.templateList || [];
			
			if(!this.templateSelectedFilter || this.templateSelectedFilter == 1) {
				tmp = tmp.filter((template) => {
					return template.status == this.templateSelectedFilter;
				});
			}

			if(this.templateCountryFilter) {
				tmp = tmp.filter((template) => {
					return (!template.label || template.label && template.label.includes(this.templateCountryFilter));
				});
			}

			return tmp;
		},
		remediationTargets() {
			return this.currentRemediation? this.currentRemediation.targets: [];
		},
		remediationTitle() {
			return this.currentRemediation && this.currentRemediation.type? this.$t('Remediation.Setup_' + this.currentRemediation.type) : "";
		},
		remediationLight() {
			return this.currentRemediation && this.currentRemediation.type && this.currentRemediation.type == Campaign.Type.RemediationLight;
		},
		remediationQRCode() {
			return this.currentRemediation && this.currentRemediation.type && this.currentRemediation.type == Campaign.Type.RemediationQRCode;
		},
		remediationUSB() {
			return this.currentRemediation && this.currentRemediation.type && this.currentRemediation.type == Campaign.Type.RemediationUSB;
		},
		remediationIRL() {
			// This special kind of Remediation actually occurs IRL - QR CODE and USB
			return this.remediationUSB || this.remediationQRCode;
		},
		remediationsCustomizable() {
			return this.currentRemediation && this.currentRemediation.type 
				&& (this.currentRemediation.type == Campaign.Type.RemediationLight
				|| this.currentRemediation.type == Campaign.Type.RemediationTraining
				|| this.currentRemediation.type == Campaign.Type.RemediationQRCode
				|| this.currentRemediation.type == Campaign.Type.RemediationUSB);
		},
		campaignDates() {
			return {
				startDate: this.$moment.utc(new Date()).local().locale(this.$i18n.locale).format("YYYY-MM-DD"),
				startTime: this.$moment.utc(new Date()).local().locale(this.$i18n.locale).format("HH:mm:ss"),
				plannedEnd: "P30D",
				sendEmailsBy: this.remediationIRL ? "PT2M" : "P25D"
			}
		},
		isAdmin() {
			return this.$checkPermission('list-company-all');
		},
	},
	methods: {
		addTargetToList () {
			var tempList = [...this.accountApproversList];
			if (this.newApprover.index > -1) {
				tempList[this.newApprover.index] = this.newApprover;
			} else {
				tempList.push(this.newApprover);
			}
			const uniqueValues = new Set(tempList.map(v => v.email));

			if (uniqueValues.size < tempList.length) {
				this.alertVariant = 'danger';
				this.alertMessage = 'Campaigns.DuplicateEmail';
				this.$refs.cgRemModalAlert.showAlert();

				Vue.set(this.fields.find(i => i.id === 'email'), 'error', true);
				this.formValid = false;
				return;
			}

			if(this.newApprover.index > -1) {
				Vue.set(this.accountApproversList, this.newApprover.index, this.newApprover);

				// Refresh table to update data
				this.$nextTick(() => {
					this.$refs.targetTable.refresh();
				});
			} else {
				this.accountApproversList.push({
					lastname: this.newApprover.lastname,
					firstname: this.newApprover.firstname,
					email: this.newApprover.email,
					language: this.newApprover.language
				});
			}

			this.newApprover = {};

			this.updateApproverList();
		},
		localizeLanguage(l) {
			let availableLanguages = this.fields.find(i => i.id === 'language');
			return availableLanguages.options[l] || l;
		},
		deleteApprover(index) {
			this.accountApproversList.splice(index, 1);
			this.updateApproverList();
		},
		editApprover(index) {
			this.newApprover = Object.assign({}, this.accountApproversList[index]);
			this.newApprover.index = index;
		},
		async updateApproverList() {
			await companyService.updateCompanyTestTargets(this.companyId, this.accountApproversList, this.currentRemediation.campaign_id, this.currentRemediation.type, true);
		},
		isValidDomain(email) {
			if(!email || !isEmail(email)) return 0;

			let trimmeredEmail = email.trim();

			// Check whether the email is contained into allowed account domain list
			return this.$checkPermission('list-company-all') || 
				((trimmeredEmail && this.emailDomain && this.emailDomain.length > 0) ? this.emailDomain.includes(trimmeredEmail.substring(trimmeredEmail.indexOf('@'))) : true);
		},
		validate(value, field) {
			field.error = false;
			if(typeof field.validator =='function' && value != '') {
				this.formValid = field.validator(value);
				if(!this.formValid) {
					field.error = true;
				} 
			}
		},
		getUserTabLabel() {
			return this.remediationIRL ? 'Navbar.Recipients' : 'Navbar.Targets';
		},
		async remediate(type) {
			this.loading = true;
			console.debug("Remediation - Remediate - Start remediating for type", type);

			this.currentRemediation = this.remediationData.filter((rem) => { return rem.type == type; })[0];

			if(!this.currentRemediation.campaign_id) {
				await companyService.createRemediationCampaign(this.companyId, type);

				await this.getRemediations();

				this.currentRemediation = this.remediationData.filter((rem) => { return rem.type == type; })[0];
			}

			this.isCardLayout = type < Campaign.Type.RemediationLight;

			if(this.remediationsCustomizable) this.countSelectedTemplates();
			if(this.remediationLight) Vue.set(this.currentRemediation, 'targets', []);

			this.targetTableParams.totalRows = this.currentRemediation.targets && this.currentRemediation.targets.length;
			this.targetTableParams.perPage = 15;
			this.targetTableParams.currentPage = 1;

			this.emptyAfterFiltered = this.$t('Analytics.Table.Empty');

			if(type < Campaign.Type.RemediationLight) {
				// cleaning field for relative select
				this.templateCountryFilter = null;
				this.templateCountryOptions = [];

				// search for targets country
				const targetCountryList = [...new Set(this.currentRemediation.targets.map(item => item.country))];
				if(targetCountryList.includes(null) && !targetCountryList.includes(this.companyData.company['country'])) {
					targetCountryList.push(this.companyData.company['country']);
				}
				// populate relative select
				targetCountryList.forEach((c) => {
					if(c) this.templateCountryOptions.push({ text: this.isoCountries[c], value: c });
				});
				this.templateCountryOptions.sort((a, b) => { return ('' + a.text).localeCompare('' + b.text); });
				this.templateCountryOptions.unshift({ text: this.$t('Template.AllCountries'), value: null });
			}

			// Retrieve Remedation associated accounts
			let tempTargets = await companyService.getCompanyTestTargets(this.companyId, { campaignType: this.currentRemediation.type });
			let hasAlreadyTargets = tempTargets && tempTargets.data.length > 0;

			if(hasAlreadyTargets) {
				this.accountApproversList = tempTargets.data;
			} else {
				this.accountApproversList = this.originalAccountApprovers;
				this.updateApproverList();
			}
			
			this.activeTargets = this.remediationIRL ? this.accountApproversList.length : this.currentRemediation.targets.length;

			this.newApprover = {};
			
			this.loading = false;

			this.$refs.remModal.show();
		},
		async triggerRemediation() {
			let targets = this.currentRemediation.targets.filter((t) => { return t.deleted != "1"; });

			// Cannot trigger with no targets!
			if(targets.length < this.minTargets && this.accountApproversList.length < this.minTargets) {
				let count = this.remediationIRL? this.accountApproversList.length : targets.length;
				this.createAlert('danger', 'Remediation.TooFewTargets', { error : count }, count);
				return;
			}

			let templates = this.currentRemediation.templateList.filter((t) => { return t.status == "1"; });

			// Cannot trigger without at least 1 template
			if(templates.length < 1) {
				this.createAlert('danger', 'Remediation.NoTemplateSelected');
				return;
			}

			if(this.remediationIRL && templates.length != 1) {
				this.createAlert('danger', 'Remediation.OneTemplateOnly');
				return;
			}

			this.loading = true;

			// For Remediation Light, save selected Templates
			if(this.remediationLight) {
				templates = templates.map(t => { return { template_id: t.template_id } });
				await campaignService.updateRemediationTemplates(this.currentRemediation.campaign_id, templates);
			}
			
			if(this.remediationsCustomizable) {
				await campaignService.updateCampaignDates(this.currentRemediation.campaign_id, this.remediationLightDates);
			}

			campaignService.initializeRemediationCampaign(this.currentRemediation.campaign_id, this.companyId, this.sendEmailBy, targets, this.currentRemediation.type).then(() => {
				console.debug("Remediation - initializeRemediationCampaign success");
				this.alertVariant = 'success';
				this.alertMessage = 'Remediation.Started';
				
				this.$refs.cgRemAlert.showAlert();
				this.$refs.remModal.hide();
				this.getRemediations();
			}).catch((err) => {
				console.err("Remediation - initializeRemediationCampaign failed", err);
				this.createAlert('danger', 'Remediation.StartError');
			}).finally(() => {
				this.loading = false;
			});
		},
		countActiveTargets() {
			this.activeTargets = 0;

			this.currentRemediation.targets.forEach((t) => {
				if(t.deleted != "1") {
					this.activeTargets += 1;
				}
			});
		},
		countSelectedTemplates() {
			let temp = this.currentRemediation.templateList.filter(t => t.status == 1);
			this.remLightTemplates = temp.length;
		},
		rowClass(item, type) {
			if (!item || type !== 'row') return;
			if (item.deleted != "1") {
				return 'dawn';
			} else {
				return 'dusk';
			}
		},
		getRemName(name) {
			if(!name) {
				return '';
			} else {
				let title = '';
				let tokens = name.split("-");
				title = this.$t(`Remediation.` + tokens[0]);
				return title;
			}
		},
		getRemSourceCampaign(name) {
			if(!name) {
				return '';
			} else {
				let tokens = name.split("-");

				return tokens[2]? this.$t('Remediation.SourceCampaign', { param: 'C-' + tokens[2] }) : ''; 
			}
		},
		getRemActualEnd(date) {
			return !date? '' : this.$t('Remediation.ActualEnd', { param: moment.tz(date, this.$account.timezone).format('DD/MM/YYYY') });
		},
		applyFilter(value) {
			// Force layout reload to preserve graph coherence
			console.debug("Remediation - ApplyFilter -", value);
			this.reloadHistory = this.reloadHistory + 1;
		},
		applyTargetFilter() {
			if(!this.targetFilter) {
				return;
			} else {
				Vue.set(this.targetFilterMetadata.find(i => i.id === this.targetFilter), 'display', true);
				this.targetFilter = null;
			}
		},
		removeTargetFilter(value) {
			Vue.set(this.targetFilterMetadata.find(i => i.id === value ), 'display', false);
			this.targetFilterModel[value] = defaultFilter[value];
			this.alertChangedQuery = true;
		},
		resetModal() {
			this.targetFilterMetadata.forEach((element) => {
				this.removeTargetFilter(element['id']);
			});
		},
		getTemplateTypeIcon(templateType) {
			switch(templateType) {
				case this.TEMPLATE_TYPE_SMS:
					return ['fas', 'sms'];
				case this.TEMPLATE_TYPE_TRAINING:
					return ['fas', 'chalkboard-teacher'];
				case this.TEMPLATE_TYPE_QRCODE:
					return ['fas', 'qrcode'];
				case this.TEMPLATE_TYPE_USB:
					return ['fab', 'usb'];
				case this.TEMPLATE_TYPE_EMAIL:
				default:
					return ['fas', 'envelope'];
			}
		},
		async buildQuery() {
			let query = {};
			this.targetFilterMetadata.forEach((el) => {
				if(el.display) {
					switch(el.id) {
						case 'campaign':
							query['campaign'] = [];
							// Create an array of objects { campaign_id: value }
							Object.keys(this.targetFilterModel[el.id]).forEach((k) => {
								if(this.targetFilterModel[el.id][k]) {
									query['campaign'].push({ [k] : this.targetFilterModel[el.id][k] });
								}
							});
							break;
						case 'target_risk':
							query['target_risk'] = [];
							// Create an array with target risk keys
							Object.keys(this.targetFilterModel[el.id]).forEach((k) => {
								if(this.targetFilterModel[el.id][k]) {
									query['target_risk'].push(k);
								}
							});
							break;
						case 'click_rate':
							query['click_rate'] = [this.targetFilterModel[el.id].min, this.targetFilterModel[el.id].max];
							break;
						default:
							query[el.id] = this.targetFilterModel[el.id];
							break;
					}
				}
			});

			//  Invoke Target query and assign result
			let result = await companyService.getRemediationLightTargets(this.companyId, query);

			// Task 226 - anonymise Target personal data according the given permissions
			if(!this.$checkPermission('view-target-details') && (result && result.data)) {
				result.data = result.data.map((el) => { return { ...el, 'firstname': this.$t('Analytics.Table.Target') + "_" + el.target_id, 
					'lastname': this.$t('Analytics.Table.Target') + "_" + el.target_id } });
			}

			Vue.set(this.currentRemediation, 'targets', result && result.data || []);
			this.targetTableParams.totalRows = this.currentRemediation.targets && this.currentRemediation.targets.length;
			this.emptyAfterFiltered = this.$t('Analytics.Table.EmptyFilter');
			this.activeTargets = this.currentRemediation.targets.length;
			this.showOptions = false;
			this.alertChangedQuery = false;
		},
		nextSlide(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			if((this.remediationsCustomizable && this.remTabIndex < 3) || this.showTargetDetails && this.remTabIndex == 0) {
				// Go to next slide
				this.remTabIndex += 1;
				if(this.remediationsCustomizable && this.remTabIndex == 3) {
					// The next tab is RECAP
					this.updateRemediationSummary();
				}
			} else {
				// Show confirmation modal
				this.$bvModal.show("remediation-confirm");
			}
		},
		deepMergeArrays(list, content) {
			// Flatten the 'content' arrays			
			const flattenedContentArray = content.reduce((acc, item) => acc.concat(item.data), []);

			return list.map(template => {
				// Find all matching items from flattenedContentArray for the given key
				const templateContent = flattenedContentArray.filter(item => item['template_id'] === template['template_id']);

				// Merge template with the matching content if any
				return templateContent.length > 0
					? { ...template, body: templateContent, languages: templateContent.length, label: template.country && template.country.split('#') }
					: template;
			});
		},
		async getTemplateContents(fullListTemplates) {
			console.debug("Remediation - getTemplateContents");

			try {
				// Gather also Template content to effectively render the Cards
				let templatePromiseArray = [];

				// Populate promise array only with template missing content!
				fullListTemplates.forEach((element) => {
					let skipTemplate = this.templates.filter(t => t.template_id === element.template_id && t.body);
					if(!skipTemplate || !skipTemplate[0]?.body) {
						templatePromiseArray.push(templateService.getTemplateDetails(element.template_id));
					}
				});

				// Await for all promises to be resolved before proceeding
				const templateContent = await Promise.all(templatePromiseArray);

				this.templates = this.deepMergeArrays(this.templates, templateContent);

				let fullBodyTemplates = [];

				fullListTemplates.map(x => {
					let t = Object.assign({}, this.templates.filter(t => t.template_id === x.template_id)[0]);
					t.status = x.status;
					fullBodyTemplates.push(t);
				});

				// Select unique Templates
				return [...new Map(fullBodyTemplates.map(item => [item['template_id'], item])).values()];
			} catch (error) { 
				console.debug("Remediation - getTemplateContents - ERROR", error);
			}
		},
		showPreviewModal(record) {
			this.templatePreview = record;
			this.$refs.cgTemplateModal.showPreviewModal(this.templatePreview);
		},
		createAlert(variant, message, alertParams, count) {
			this.alertVariant = variant;
			this.alertMessage = message;
			this.alertParams = alertParams;
			this.alertCount = count;
			this.$refs.cgRemModalAlert.showAlert();
		},
		toggleFilterModel(attribute, key) {
			if(!this.targetFilterModel[attribute].hasOwnProperty(key)) {
				this.targetFilterModel[attribute] = Object.assign(this.targetFilterModel[attribute], { [key] : false });
			}
			if(attribute === 'campaign') {
				switch(this.targetFilterModel[attribute][key]) {
					case 1:
						this.targetFilterModel[attribute][key] = -1;
						break;
					case -1:
						this.targetFilterModel[attribute][key] = null;
						break;
					default:
						this.targetFilterModel[attribute][key] = 1;
						break;
				}
			} else {
				this.targetFilterModel[attribute][key] = !this.targetFilterModel[attribute][key];
			}
		},
		updateRemediationSummary() {
			this.updateRemediationDates();

			if(this.remediationIRL) {
				this.activeTargets = this.accountApproversList.length || 0;
			}
		},
		updateRemediationDates() {
			const plannedStartDate = this.campaignDates.startDate;
			const plannedStartTime = this.campaignDates.startTime;

			// Set up Start Date combining selected day and time
			const plannedStart = this.$moment(plannedStartDate + ' ' + plannedStartTime).local().locale(this.$i18n.locale).utc();

			// Set up the duration of the campaign and the deadline for sending emails 
			const CampaignDuration = this.$moment.duration(this.campaignDates.plannedEnd.toUpperCase() || this.companyData.company.campaign_duration);
			const SendEmailsByDuration = this.$moment.duration(this.campaignDates.sendEmailsBy.toUpperCase() || this.companyData.company.send_email_duration);

			// Set up Planned End and "Send emails by" dates
			const plannedEnd = this.$moment(plannedStart).add(CampaignDuration).local().locale(this.$i18n.locale).utc();
			const sendEmailsBy = this.$moment(plannedStart).add(SendEmailsByDuration).local().locale(this.$i18n.locale).utc();
			
			// Format new Campaign dates
			this.remediationLightDates = {
				plannedStart: plannedStart.format('YYYY-MM-DD HH:mm:ss'),
				sendEmailsBy: sendEmailsBy.format('YYYY-MM-DD HH:mm:ss'),
				plannedEnd: plannedEnd.format('YYYY-MM-DD HH:mm:ss')
			};
		},
		updateCampaignDates() {
			// Reset invalidDateField array
			this.invalidDateField = [];
			clearTimeout(this.debounceUpdateTimer);

			// Set a debounce timer to avoid instant update
			this.debounceUpdateTimer = setTimeout(() => {
				const plannedStartDate = this.campaignDates.startDate;
				const plannedStartTime = this.campaignDates.startTime;

				// Set up Start Date combining selected day and time
				const plannedStart = this.$moment(plannedStartDate + ' ' + plannedStartTime).local().locale(this.$i18n.locale).utc();

				// Set up the duration of the campaign and the deadline for sending emails 
				const CampaignDuration = this.$moment.duration(this.campaignDates.plannedEnd.toUpperCase() || this.companyData.company.campaign_duration);
				const SendEmailsByDuration = this.$moment.duration(this.campaignDates.sendEmailsBy.toUpperCase() || this.companyData.company.send_email_duration);

				// Set up Planned End and "Send emails by" dates
				const plannedEnd = this.$moment(plannedStart).add(CampaignDuration).local().locale(this.$i18n.locale).utc();
				const sendEmailsBy = this.$moment(plannedStart).add(SendEmailsByDuration).local().locale(this.$i18n.locale).utc();

				// Planned end date must be greater then the planned start date
				if (plannedEnd.isBefore(plannedStart)) {
					this.invalidDateField.push('campaignDuration');
					this.createAlert('danger', 'Campaigns.InvalidCampaignPlannedEnd');
					return;
				}

				// "Send emails by" date must be greater then the planned start date and lower than planned end date
				if (sendEmailsBy.isBefore(plannedStart) || sendEmailsBy.isAfter(plannedEnd)) {
					this.invalidDateField.push('sendEmailsBy');
					this.createAlert('danger', 'Campaigns.InvalidSendEmailsByDuration');
					return;
				}

				// Retrieve campaign ID
				const campaignId = this.currentRemediation.campaign_id;

				// Format new Campaign dates
				this.remediationLightDates = {
					plannedStart: plannedStart.format('YYYY-MM-DD HH:mm:ss'),
					sendEmailsBy: sendEmailsBy.format('YYYY-MM-DD HH:mm:ss'),
					plannedEnd: plannedEnd.format('YYYY-MM-DD HH:mm:ss')
				};

				// Update Campaign dates
				campaignService.updateCampaignDates(campaignId, this.remediationLightDates).then((res) => {
					console.debug("Campaign - updateCampaignDates success", res);
					this.createAlert('success', 'Campaigns.UpdatedCampaignDuration');
				}).catch((error) => {
					console.error("Campaign - updateCampaignDates error", error);
					this.createAlert('danger', 'Campaigns.UpdatedCampaignDurationError');
				});
			}, 500);
		},
		async getCompletedRemediations() {
			let remediationList = [];

			await Promise.all(this.remediationTypes.map(async (type) => {
				let result = await companyService.getCompletedFinalRemediationCampaigns(this.companyId, type);
				if(result && result.data) {
					result.data = result.data.sort((a, b) => { return new Date(b.actual_end) - new Date(a.actual_end); });
					remediationList.push({ data: result.data, type: type });
				}
			}));

			this.remHistory = remediationList;
		},
		async getRemediations(justCreated = null) {
			let result = await companyService.getCurrentRemediationCampaigns(this.companyId, this.showTargetDetails);

			if(justCreated) { 
				this.dataAvailable = true;
				this.loading = false;
			}

			// Prepare Remediations items, remove first elements in array
			let remTemp = result.data.slice(3);

			let validTemplateTypes = [TemplateType.Email];
			if(this.companyData.sms_enabled) {
				validTemplateTypes.push(TemplateType.SMS);
			}

			let remediationLightTemplates = this.templates.filter((t) => { return t.deleted != "1" && validTemplateTypes.includes(t.type); });
			let remediationQRTemplates = this.templates.filter((t) => { return t.deleted != "1" && [TemplateType.QRCode].includes(t.type); });
			let remediationUSBTemplates = this.templates.filter((t) => { return t.deleted != "1" && [TemplateType.USB].includes(t.type); });
			let remediationTrainingTemplates = this.templates.filter((t) => { return t.deleted != "1" && [TemplateType.Training].includes(t.type); });

			Vue.set(remTemp.find(i => i && i.type === Campaign.Type.RemediationLight), 'templates', remediationLightTemplates);
			Vue.set(remTemp.find(i => i && i.type === Campaign.Type.RemediationQRCode), 'templates', remediationQRTemplates);
			Vue.set(remTemp.find(i => i && i.type === Campaign.Type.RemediationUSB), 'templates', remediationUSBTemplates);
			Vue.set(remTemp.find(i => i && i.type === Campaign.Type.RemediationTraining), 'templates', remediationTrainingTemplates);
			
			this.remediationData = remTemp.filter((r) => { return r && r.type && this.remType.includes(r.type); });

			this.remediationData.forEach(async(r) => {
				// Check if target data should be anonymized
				r.targets.forEach((t) => {
					if(this.showTargetDetails == 1) {
						t.firstname = this.$t('Analytics.Table.Target') + "_" + t.target_id;
						Vue.set(this.targetFields.find(i => i.key === 'lastname'), 'class', 'd-none');
						Vue.set(this.targetFields.find(i => i.key === 'org_1'), 'class', 'd-none');
					}
					t.deleted = null;
				});
			});

			for (let i in this.remediationData) {
				// Retrieve Template contents
				this.remediationData[i].templateList = this.remediationData[i].templates? 
					await this.getTemplateContents(this.remediationData[i].templates) : [];
			}
		},
	},
	async created() {
		this.loading = true;

		let remTypeTemp = await companyService.hasRemediationTab(this.companyId);
		
		if(remTypeTemp && remTypeTemp.data == false) {
			this.$router.replace("/");
		} else {
			this.remType = remTypeTemp && remTypeTemp.data;
		}

		await companyService.cleanUpPendingRemediations(this.companyId);

		const config = await syspropService.getConfig();
		
		let companyRes = await companyService.getCompany(this.companyId);
		this.companyData = {
			company: companyRes.data,
			contact: config.data.defaults.contact,
			cgsmtp: config.data.defaults.cgsmtp,
			gp_landing_url: config.data.defaults.gp_landing_url,
			img_url: config.data.defaults.campaign.img_url || `{{.BaseURL}}`,
			sms_enabled: !!companyRes.data.sms_enabled || false,
			default_language: companyRes.data.default_language
		}

		if(companyRes.data.email_domain) {
			this.emailDomain = companyRes.data.email_domain.split(/,| /).filter( dom => { return  dom!=''} );
			this.validDomain = {
				count: this.emailDomain.length,
				domain: this.emailDomain.join(', ')
			}
		}

		this.minTargets = config.data.defaults.remediation_campaign.minTargets || 1;

		// 0 no target details, 1 anonym targets with details, 2 real targets with details
		this.showTargetDetails = this.$checkPermission('view-target-risk')? this.$checkPermission('view-target-details')? 2 : 1 : 0;

		// Target Table Header Fields
		this.targetFields = [
			{ key: "firstname", label: this.$t('Targets.FirstName'), sortable: true, class: "text-left" },
			{ key: "lastname", label: this.$t('Targets.LastName'), sortable: true, class: "text-left" },
			{ key: "org_1", label: orgs && orgs.org_1 || this.$t('Targets.Org_1'), sortable: true, class: 'd-none d-lg-table-cell text-center' },
			{ key: "click_rate", label: this.$t('Analytics.Table.ClickRate'), sortable: true, class: 'text-center' },
			{ key: "deleted", label: this.$t('Analytics.Table.Active'), sortable: false, thClass: 'type-column' }
		];

		this.accountApproversFields = [
			{ key: "firstname", label: this.$t('Targets.FirstName'), sortable: true },
			{ key: "lastname", label: this.$t('Targets.LastName'), sortable: true },
			{ key: "email", label: this.$t('Targets.Email'), sortable: true },
			{ key: "language", label: this.$t('Targets.Language'), sortable: true },
			{ key: "actions", label: this.$t('Targets.Actions'), sortable: false }
		];

		// Get all languages that appear in template content
		var templateLanguages = await companyService.getTemplateLanguages(this.companyId);
		var landingPageLanguages = await companyService.getLandingPageLanguages(this.companyId);

		// Get all system languages
		var appLanguages = await syspropService.getLanguages();

		// Extract from the language arrays the ISO code
		var tl = templateLanguages.data.map(lan => lan.language_id);
		var lpl = landingPageLanguages.data.map(lan => lan.language_id);
		var al = appLanguages.data.map(lan => lan.language_id);

		// Merge the result to obtain the target available language dropdown list
		var usedLanguages = [...new Set(tl.concat(al).concat(lpl))];
		
		// Create the object in the form (key, value) with key the ISO code and value the translation according to user's locale
		var obj = {};
		usedLanguages.forEach((lan) => {
			if(this.$langs[lan]) {
				obj[lan] = this.$langs[lan];
			}
		});

		Vue.set(this.fields.find(i => i.id === 'language'), 'options', obj);
		Vue.set(this.fields.find(i => i.id === 'email'), 'validator', this.isValidDomain);

		if(this.$checkPermission('approve-campaign')) {
			let tempAccounts = await accountService.getCampaignApprovers(this.companyId);
			this.originalAccountApprovers = tempAccounts.data;
		} else {
			this.originalAccountApprovers = [];
		}

		let scope = (this.$checkPermission('list-company-all'))? TemplateScope.GlobalAndCompany : TemplateScope.Company;
		let templateRes = await templateService.searchAllTemplates('', scope, this.companyId);
		this.templates = templateRes.data.map(t => ({ ...t, status: null }));

		await this.getCompletedRemediations();
		this.applyFilter(null);

		this.sendEmailByOptions = [
			{ value: 86400*1000*7/2, text: this.$t('Remediation.TwiceWeek') },
			{ value: 86400*1000*7, text: this.$t('Remediation.OnceWeek') },
			{ value: 86400*1000*14, text: this.$t('Remediation.OnceTwoWeeks') }
		];

		this.remOptions.push({ value: null, text: this.$t('Remediation.All')});

		this.remediationTypes.forEach(element => {
			this.remOptions.push({ value: element, text: this.$t('Remediation.R' + element) });
		});

		this.templateFields = [
			{ key: 'name', label: this.$t('Template.Name'), sortable: true, class: "text-ellipsis", tdClass: "border-right" },
			{ key: 'tags', label: this.$t('Template.Tags'), sortable: true, class: "d-none d-md-table-cell text-ellipsis", tdClass: "border-right" },
			{ key: 'difficulty', label: this.$t('Template.Difficulty'), sortable: true, class: "d-none d-lg-table-cell text-ellipsis", thClass: "type-difficulty", tdClass: "border-right" },
			{ key: 'type', label: this.$t('Template.Type'), sortable: true, thClass: "type-column", tdClass: "border-right" },
			{ key: 'status', label: this.$t('Remediation.SelectedLabel'), sortable: false, thClass: "type-column" }
		];

		this.templateSelectedOptions = [
			{ value: 2, text: this.$t('Remediation.AllRemediationTemplates') },
			{ value: 1, text: this.$t('Remediation.Included') },
			{ value: null, text: this.$t('Remediation.NotIncluded') }
		];

		// Initialize Template Filter to "show all available templates"
		this.templateSelectedFilter = 2;

		this.TEMPLATE_TYPE_SMS = TemplateType.SMS;
		this.TEMPLATE_TYPE_EMAIL = TemplateType.Email;
		this.TEMPLATE_TYPE_TRAINING = TemplateType.Training;
		this.TEMPLATE_TYPE_QRCODE = TemplateType.QRCode;
		this.TEMPLATE_TYPE_USB = TemplateType.USB;

		this.RiskGroup = RiskGroup;

		this.minTemplateAllowed = config.data.defaults.remediation_campaign.minimumTemplates || companyRes.data.n_min_campaign_templates;

		// Retrieve Company Org labels if defined
		let companyOrgLabels = await companyService.getCompanyOrgLabels(this.companyId);
		let orgs = companyOrgLabels && companyOrgLabels.data && companyOrgLabels.data[0] || {};

		// Retrieve unique Company Org Values for each level
		let companyOrgValues = await companyService.getCompanyOrgValues(this.companyId);
		let orgValues = companyOrgValues && companyOrgValues.data || {};

		// Retrieve Archived Campaigns
		let archive = await companyService.getCampaignList(this.companyId, { status: Campaign.Status.Archived });
		let campaigns = [];
		if(archive && archive.data && archive.data.length > 12) {
			campaigns = archive.data.slice(0,12);
		} else {
			campaigns = archive.data;
		}

		// Metadata for Target Filters
		this.targetFilterMetadata = [];

		// Select options for Target Filter feature
		this.targetOptions = [{ value: null, text: this.$t('Remediation.Filter.SelectFilter') }];

		Object.keys(orgValues).forEach((org, index) => {
			if(orgValues[org].length > 1 || orgValues[org].length == 1 && orgValues[org][0][org]) {
				// Filter by enabled orgs
				if(this.companyData.company && this.companyData.company['use_'+[org]]) {
					let data = orgValues && orgValues[org].map(o => ({ value: o[org], text: o[org] })) || [];
					data.unshift({ value: null, text: this.$t('General.SelectOption') });
					this.targetFilterMetadata.push({ id: org, label: (orgs && orgs[org]) || this.$t('Targets.Org_'+(index+1)), type: 'select', display: false, options: data });
					this.targetOptions.push({ value: org, text: orgs && orgs[org] || this.$t('Targets.Org_'+(index+1)) });
				}
			} 
		});

		this.targetOptions.push(
			{ value: 'click_rate', text: this.$t('Remediation.Filter.Clickrate') },
			{ value: 'target_risk', text: this.$t('Remediation.Filter.TargetRisk') },
			{ value: 'campaign', text: this.$t('Remediation.Filter.Behaviour') },
		);

		this.targetFilterMetadata.push(
			{ id: 'click_rate', label: this.$t('Remediation.Filter.Clickrate'), type: 'click-rate', display: false },
			{ id: 'target_risk', label: this.$t('Remediation.Filter.TargetRisk'), type: 'target-risk', display: false },
			{ id: 'campaign', label: this.$t('Remediation.Filter.Behaviour'), type: 'campaign', display: false, data: campaigns.sort((a,b) => { return a.seq_no - b.seq_no; }) }
		);

		// In order to activate reactivity on Campaign Widget, add Campaign IDs list to the defaultFilter model
		// Also, to suppress "unused variable" a which is the index in [0, 12] at most, a dirty trick was used 
		defaultFilter['campaign'] = Object.assign({}, ...Object.entries({...campaigns.map((c) => c.campaign_id)}).map(([a,b]) => ({ [b]: a < 0 ? false : null })));

		this.targetFilterModel = JSON.parse(JSON.stringify(defaultFilter));

		this.isoCountries = await this.$isoCountriesLanguages.getCountries(this.$i18n.locale);

		await this.getRemediations(true);

	}
};
</script>

<style lang="less">
#remediation {
	.remediation-title {
		font-size: 18px; 
		font-weight: bold; 
		vertical-align: sub;
	}
}
#remediation-modal {
	overflow-y: hidden !important;
	.timeline {
		width: 130px;
		&.center {
			width: 190px;
		}
	}
	.day {
		&.first {
			left: 121px;
		}
		&.last {
			left: 309px;
		}
	}
	.date-widget {
		width: 450px;
	}
	.campaign-time, .campaign-date {
		label {
			font-weight: 700;
		}
	}
	.target-risk-remove-filter {
		height: 33px;
		margin-top: 10px;
	}
	.label-target_risk {
		label {
			line-height: 44px;
		}
	}
	.target-risk-cell {
		cursor: pointer;
		height: 50px;
		&.active {
			background-color: teal;
			color: white;
		}
		&.not-clicked {
			background-color: #ca2020;
			color: white;
		}
		svg.fa-equals {
			color: transparent;
		}
	}
	.label-campaign > label {
		line-height: 40px;
	}
	.campaign-legend {
		height: 25px;
		width: 25px;
		color: white;
		text-align: center;
		line-height: 26px;
		display: inline-block;
		&.not-clicked {
			background-color: teal;
		}
		&.clicked {
			background-color: #ca2020;
		}
	}
	.bg-info {
		background-color: #18616D!important;
	}
	.active-target-counter {
		position: absolute;
		right: 24px;
		margin-bottom: 0px !important;
		border-radius: 8px;
		padding: 4px 8px;
		&.target-valid {
			border: 1px solid teal;
			background-color: teal;
			color: white;
		}
		&.target-invalid {
			border: 1px solid lightgrey;
			background-color: lightgrey;
		}
	}
	.modal-dialog {
		margin-top: 24px;
	}
		.tab-content>.tab-pane {
				height: 680px;
		}
	.type-column {
		width: 50px;
	}
	.type-difficulty {
		width: 180px;
	}
	.template-layout {
		border: @cg-border-light;
		max-height: 440px;
		overflow-y: auto;
		border-radius: 3px;
	}
	.table {
		margin-bottom: 0px;
	}
	.b-table-sticky-header.rem-template-table {
		border: none !important;
	}
}

.custom-switch .custom-control-label {
		&::before {
				border-color: @cg-dark-gray-text-color;
				background-color: @cg-secondary-light-text-color;
		}
		&::after {
				color: @cg-dark-gray-text-color;
				background-color: @cg-dark-gray-text-color;
		}
}
.widget-title {
	font-size: 12px;
}
.target-risk-cell {
	border-top: 1px solid lightgrey;
	border-left: 1px solid lightgrey;
	border-bottom: 1px solid lightgrey;
	&:last-of-type {
		border-right: 1px solid lightgrey;
	}
}
.border-bottom {
	border-bottom: @cg-border-light;
}
.border-top {
	border-top: @cg-border-light;
}
.border-right {
	border-right: @cg-border-light;
}
.fade-enter-active, .fade-leave-active {
	transition: all 2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
// Large devices (desktops, 992px and up)
@media only screen and (max-width: 991px) {
	.target-risk-cell {
		padding-right: 2px;
		padding-left: 2px;
	}
}
// Extra large devices (large desktops, 992px to 1200px)
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.target-risk-cell {
		padding-right: 4px;
		padding-left: 4px;
	}
}
</style>